<template>
  <div
    id="opening-section"
    :class="{
      'd-flex align-items-center justify-content-center pt-17-5': isDesktop
    }"
    class="position-relative"
    :style="styles"
  >
    <div name="content">
      <section name="quotes">
        <BorderedText>
          <p class="q-content">{{ $weddingData.quotes }}</p>
          <template v-if="$weddingData.quotesDesc">
            <p class="q-desc mt-2-5">{{ $weddingData.quotesDesc }}</p>
          </template>
        </BorderedText>
      </section>

      <section name="bride-groom" class="mt-16-5">
        <div class="d-flex justify-content-evenly">
          <Avatar type="bride" :name="$weddingData.brideFullName" :order="$weddingData.brideChildOrder" :father="$weddingData.brideFather" :mother="$weddingData.brideMother"/>
          <Avatar type="groom" :name="$weddingData.groomFullName" :order="$weddingData.groomChildOrder" :father="$weddingData.groomFather" :mother="$weddingData.groomMother"/>
        </div>
      </section>
    </div>

    <div name="decorations">
      <OpeningDecoration />
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/sections/opening/Avatar"
import OpeningDecoration from "@/components/sections/opening/OpeningDecoration"

export default {
  components: { Avatar, OpeningDecoration },
  data() {
    return {
      css: {
        qContentFontFamily: "opensansR",
        qContentFontSize: "1rem",
        qContentColor: "#161616",
        qDescFontFamily: "opensansB",
        qDescFontSize: "1rem",
        qDescColor: "#161616",

        ...this.$sections.opening
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--q-content-font-family': css.qContentFontFamily,
        '--q-content-font-size': css.qContentFontSize,
        '--q-content-color': css.qContentColor,
        '--q-desc-font-family': css.qDescFontFamily,
        '--q-desc-font-size': css.qDescFontSize,
        '--q-desc-color': css.qDescColor
      }

      const mobile = {
        '--m-q-content-font-size': css.m_qContentFontSize,
        '--m-q-desc-font-size': css.m_qDescFontSize
      }
      
      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style scoped>
  p.q-content {
    font-family: var(--q-content-font-family);
    font-size: var(--q-content-font-size);
    color: var(--q-content-color);
  }

  p.q-desc {
    font-family: var(--q-desc-font-family);
    font-size: var(--q-desc-font-size);
    color: var(--q-desc-color);
    background: var(--q-desc-color);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
</style>

<style scoped>
  .mobile #opening-section {
    padding-top: 1.25rem !important;
  }

  .mobile #opening-section div[name=content] {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .mobile p.q-content {
    font-size: var(--m-q-content-font-size);
  }

  .mobile p.q-desc {
    font-size: var(--m-q-desc-font-size);
  }
</style>