var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",class:{
    'd-flex align-items-center justify-content-center mt-12-5': _vm.isDesktop,
    'px-3-752': _vm.isMobile
  },attrs:{"id":"rsvp-section"}},[_c('div',{attrs:{"name":"content","data-aos":"fade-up"}},[(_vm.isMobile)?[_c('Title',{staticClass:"mt-6",attrs:{"text":"R S V P"}})]:_vm._e(),_c('Form',{class:{
        'maxw-300 w-vw-75 py-9 px-25': _vm.isDesktop,
        'mt-2 pt-5 pb-8 px-7-5': _vm.isMobile
      }},[_c('section',{class:{ isDisabled: _vm.isDisabled },attrs:{"name":"title"}},[(_vm.isDesktop)?[_c('Title',{attrs:{"text":"R S V P"}})]:_vm._e(),_c('p',{staticClass:"desc text-center mt-2-752",style:(_vm.styles)},[_vm._v(" Merupakan suatu kehormatan apabila Bapak/Ibu/Saudara/i berkenan hadir atau memberikan doa bila berhalangan. Atas kehadiran dan doanya, kami ucapkan terimakasih. ")])],2),_c('section',{staticClass:"mt-7-5",attrs:{"name":"form-input"}},[_c('div',{class:{ isDisabled: _vm.isDisabled }},[_c('Input',{staticClass:"mb-7-5",attrs:{"label":"Nama","placeholder":"Tulis Namamu...","disabled":_vm.isInvited},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('TextArea',{class:{
              'mb-7-5': _vm.isDesktop,
              'mb-3-752': _vm.isMobile
            },attrs:{"label":"Pesan","placeholder":"Tulis Pesanmu...","limit":"300","disabled":_vm.isDisabled},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('div',{class:{
              'd-flex': _vm.isDesktop
            }},[_c('Radio',{class:{
                'mb-7-5 mr-25': _vm.isDesktop,
                'mb-3-752': _vm.isMobile
              },attrs:{"name":"kehadiran","label":"Apa kamu akan hadir?","radio_items":_vm.attendance_options},model:{value:(_vm.form.attendance),callback:function ($$v) {_vm.$set(_vm.form, "attendance", $$v)},expression:"form.attendance"}}),_c('Radio',{class:{
                'mb-7-5': _vm.isDesktop,
                'mb-3-752': _vm.isMobile
              },attrs:{"name":"jumlah","label":"Bagaimana kamu akan hadir?","radio_items":_vm.how_options},model:{value:(_vm.form.how),callback:function ($$v) {_vm.$set(_vm.form, "how", $$v)},expression:"form.how"}})],1)],1),_c('div',{staticClass:"d-flex justify-content-center",class:{
            'mt-10-252': _vm.isDesktop
          }},[_c('Button',{attrs:{"text":_vm.buttonText},nativeOn:{"click":function($event){return _vm.$buttonClick(_vm.buttonFunc)}}})],1)])])],2),_c('div',{attrs:{"name":"decorations"}},[_c('RSVPDecoration')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }