import { render, staticRenderFns } from "./Avatar.vue?vue&type=template&id=100845dc&scoped=true&"
import script from "./Avatar.vue?vue&type=script&lang=js&"
export * from "./Avatar.vue?vue&type=script&lang=js&"
import style0 from "./Avatar.vue?vue&type=style&index=0&id=100845dc&scoped=true&lang=css&"
import style1 from "./Avatar.vue?vue&type=style&index=1&id=100845dc&mobile=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100845dc",
  null
  
)

export default component.exports