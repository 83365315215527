<template>
  <div
    id="rsvp-section"
    :class="{
      'd-flex align-items-center justify-content-center mt-12-5': isDesktop,
      'px-3-752': isMobile
    }"
    class="position-relative"
  >
    <div name="content" data-aos="fade-up">
      <template v-if="isMobile">
        <Title text="R S V P" class="mt-6"/>
      </template>

      <Form
        :class="{
          'maxw-300 w-vw-75 py-9 px-25': isDesktop,
          'mt-2 pt-5 pb-8 px-7-5': isMobile
        }">
        <section name="title" :class="{ isDisabled }">
          <template v-if="isDesktop">
            <Title text="R S V P" />
          </template>
          <p
            class="desc text-center mt-2-752"
            :style="styles"
          >
            Merupakan suatu kehormatan apabila Bapak/Ibu/Saudara/i berkenan hadir atau memberikan doa bila berhalangan. Atas kehadiran dan doanya, kami ucapkan terimakasih.
          </p>
        </section>

        <section name="form-input" class="mt-7-5">
          <div :class="{ isDisabled }">
            <Input
              class="mb-7-5"
              label="Nama"
              placeholder="Tulis Namamu..."
              v-model="form.name"
              :disabled="isInvited"
            />

            <TextArea
              :class="{
                'mb-7-5': isDesktop,
                'mb-3-752': isMobile
              }"
              label="Pesan"
              placeholder="Tulis Pesanmu..."
              limit="300"
              v-model="form.message"
              :disabled="isDisabled"
            />

            <div
              :class="{
                'd-flex': isDesktop
              }"
            >
              <Radio
                :class="{
                  'mb-7-5 mr-25': isDesktop,
                  'mb-3-752': isMobile
                }"
                name="kehadiran"
                label="Apa kamu akan hadir?"
                :radio_items="attendance_options"
                v-model="form.attendance"
              />

              <Radio
                :class="{
                  'mb-7-5': isDesktop,
                  'mb-3-752': isMobile
                }"
                name="jumlah"
                label="Bagaimana kamu akan hadir?"
                :radio_items="how_options"
                v-model="form.how"
              />
            </div>
          </div>

          <div
            class="d-flex justify-content-center"
            :class="{
              'mt-10-252': isDesktop
            }"
          >
            <Button :text="buttonText" @click.native="$buttonClick(buttonFunc)"/>
          </div>
        </section>
      </Form>
    </div>

    <div name="decorations">
      <RSVPDecoration />
    </div>
  </div>
</template>

<script>
import RSVPDecoration from "@/components/sections/rsvp/RSVPDecoration"

export default {
  components: { RSVPDecoration },
  data() {
    return {
      isDisabled: false,
      isInvited: false,
      attendance_options: [
        { id: 'hadir', value: true, label: 'Saya akan hadir' },
        { id: 'tidak_hadir', value: false, label: 'Maaf, tidak dapat hadir' }
      ],
      how_options: [
        { id: 'sendiri', value: 1, label: 'Sendiri' },
        { id: 'berdua', value: 2, label: 'Berdua' }
      ],
      form: {
        id: null,
        name: "",
        message: "",
        attendance: true,
        how: 1,
        username: null,
      },
      css: {
        descFontFamily: "opensansR",
        descFontSize: "1rem",
        descColor: "#161616",

        ...this.$sections.rsvp
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--desc-font-family': css.descFontFamily,
        '--desc-font-size': css.descFontSize,
        '--desc-color': css.descColor
      }

      const mobile = {
        '--m-desc-font-size': css.m_descFontSize,
      }

      return { ...desktop, ...mobile }
    },
    buttonText() {
      return this.isDisabled ? "Ubah" : "Konfirmasi"
    },
    buttonFunc() {
      return this.isDisabled ? (() => this.isDisabled = false) : this.submit
    }
  },
  methods: {
    __init() {
      this.getRsvpName()
    },
    async submit() {
      const validated = this.validate()

      if(validated) {
        let res = await this.$rsvpService.submitRsvp(this.form)
  
        if(res.success) {
          this.$alertSuccess("Terimakasih atas doa dan ucapannya :)")

          if(this.$guestData) {
            this.isDisabled = true

            this.form.id = res.data.id
          }
        } else {
          this.$alertFail(res.message)
        }
      } else {
        this.$alertFail("Harap isi form rsvp dengan lengkap")
      }
    },
    validate() {
      const excludes = ["attendance", "how", "username", "id"]
      
      for(const key in this.form) {
        if(!excludes.includes(key)) {
          if(!this.form[key]) {
            return false
          }
        }
      }

      return true
    },
    getRsvpName() {
      if(this.$guestData) {
        this.form.name = this.$guestData.name
        this.form.username = this.$guestData.username

        this.isInvited = true

        this.form = { ...this.form, ...this.$guestData.rsvp }

        if(this.$guestData.rsvp) this.isDisabled = true
      }
    }
  },
  mounted() {
    this.__init()
  }
}
</script>

<style scoped>
  .maxw-300 {
    max-width: calc(75rem - (6.25rem * 2)) !important;
  }

  p.desc {
    font-family: var(--desc-font-family);
    font-size: var(--desc-font-size);
    color: var(--desc-color);
  }

  .isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>

<style mobile scoped>
 .mobile p.desc {
  font-size: var(--m-desc-font-size);
 }
</style>