<template>
  <div id="floating-button">
    <div 
      class="floating-container d-flex justify-content-evenly align-items-center" 
      :style="{
          backgroundColor: css.backgroundColor,
          borderColor: css.borderColor
        }"
      >
      <template v-for="(icon, index) in icons">
        <template v-if="icon.show">
          <img
            :key="index" 
            class="menu-icon" 
            :class="{ active: activeMenu == icon.menu }"
            @click="scrollTo(icon.menu)"
            :src="`/icons/${iconDir}/${activeMenu == icon.menu ? icon.activeSrc : icon.src}`" 
          >
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['viewed_menu', 'showRsvp'],
  data() {
    return {
      activeMenu: "opening-section",
      iconDir: this.$baseComponents.icon.dir,
      icons: [
        {
          src: "wedding-couple.svg",
          activeSrc: "wedding-couple-active.svg",
          menu: "opening-section",
          show: true
        },
        {
          src: "wedding-day.svg",
          activeSrc: "wedding-day-active.svg",
          menu: "time-section",
          show: true
        },
        {
          src: "wedding-arch.svg",
          activeSrc: "wedding-arch-active.svg",
          menu: "location-section",
          show: true
        },
        {
          src: "wedding-planning.svg",
          activeSrc: "wedding-planning-active.svg",
          menu: "rsvp-section",
          show: this.$optionalSections.rsvp && this.showRsvp
        },
        {
          src: "picture.svg",
          activeSrc: "picture-active.svg",
          menu: "gallery-section",
          show: this.$optionalSections.gallery
        },
      ],
      css: {
        ...this.$baseComponents.floatingMenu
      }
    }
  },
  methods: {
    scrollTo(menu_id) {
      const menu = document.getElementById(menu_id)

      this.activeMenu = menu_id
      
      menu.scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
}
</script>

<style mobile scoped>
  .mobile #floating-button {
    position: fixed;
    bottom: 40px;
    right: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    padding-left: 2.188rem;
    padding-right: 2.188rem;
    min-height: 4.3rem;
  }

  .mobile .floating-container {
    border-radius: 1.875rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    border-width: 1px;
    border-style: solid;
    box-shadow: 2px 2px 5px -3px rgba(255,255,255,0.75);
    -webkit-box-shadow: 2px 2px 5px -3px rgba(255,255,255,0.75);
    -moz-box-shadow: 2px 2px 5px -3px rgba(255,255,255,0.75);
  }

  .mobile img.menu-icon {
    width: 30px;
    height: 30px;
  }

  .mobile img.menu-icon.active {
    width: 40px;
    height: 40px;
  }
</style>